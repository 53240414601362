/* ======================
  LawPage Responsive File
====================== */
/* NavBar */
.lp-navbar {
  @include media-breakpoint-up(xl) {
    &.expanded {
      width: 27.5rem;
    }
  
    &.collapsed {
      width: 7rem;
    }
  }

  @include media-breakpoint-down(xl) {
    height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
    height: var(--vh, 1vh);
    left: -28rem;
    position: fixed;
    top: 0;
    transition: 0.4s ease-in-out;
    width: 27.5rem;
    z-index: 990;

    &.mobile-opened {
      left: 0;
    }
  }
}

/* Main Menu */
.lp-main-menu-item {
  > a {
    @include media-breakpoint-up(xl) {
      .collapsed & {
        justify-content: center;
      }
    }
  }

  .lp-main-menu-item-icon {
    @include media-breakpoint-up(xl) {
      .expanded & {
        margin-right: 1.6rem;
      }
    }

    @include media-breakpoint-down(xl) {
      margin-right: 1.6rem;
    }
  }

  .lp-main-menu-item-text {
    @include media-breakpoint-up(xl) {
      .collapsed & {
        display: none;
      }
    }
  }
}

/* NavBar Toggle */
.lp-navbar-toggle {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}

/* Mobile Trigger */
.lp-mobile-trigger {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

/* Mobile Overlay */
.lp-mobile-overlay {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

/* Title */
.lp-title {
  /* Page Title */
  &.page {
    @include media-breakpoint-up(md) {
      min-height: 3.7rem;
    }

    @include media-breakpoint-down(md) {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 1.6rem;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 2.4rem;
    }

    .lp-title-text {
      @include media-breakpoint-down(xl) {
        padding-left: 4.8rem;
      }

      @include media-breakpoint-up(md) {
        line-height: 1.25;
      }

      @include media-breakpoint-between(sm,md) {
        line-height: 1.5;
        min-height: 4rem;
      }

      @include media-breakpoint-up(sm) {
        margin: 0;
      }

      @include media-breakpoint-down(sm) {
        line-height: 1.25;
        margin-bottom: 0.8rem;
      }

      span.lp-sync-message {
        @include media-breakpoint-up(lg) {
          display: inline-flex;
        }
  
        @include media-breakpoint-between(md,lg) {
          display: none;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      span.lp-shared-message {
        @include media-breakpoint-down(lg) {
          padding: 0.4rem;
        }

        .lp-tooltip-icon {
          @include media-breakpoint-down(lg) {
            margin: 0;
          }

          svg {
            @include media-breakpoint-down(lg) {
              margin: 0;
            }
          }
        }

        > span:not(.lp-tooltip-icon) {
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }
    }

    .lp-title-actions {
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        width: 100%;
      }

      .btn {
        @include media-breakpoint-down(xxl) {
          font-size: 2rem;
          padding: 0.8rem;
        }

        > svg {
          @include media-breakpoint-down(xxl) {
            margin-right: 0;
          }
        }

        > span {
          @include media-breakpoint-down(xxl) {
            display: none;
          }
        }
      }
    }
  }
}

/* Login Box */
.lp-login-box {
  @include media-breakpoint-down(lg) {
    margin: 2.4rem auto;
  }

  .lp-msal-login-btn {
    @include media-breakpoint-down(sm) {
      padding: 2.4rem 1.6rem;
    }
  }
}

/* 404 Page */
.lp-404-page {
  .lp-404-wrapper {
    h1 {
      @include media-breakpoint-down(sm) {
        font-size: 3.2rem;
      }
    }

    h4 {
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
  }

  .lp-404-text {
    @include media-breakpoint-down(lg) {
      padding: 2.4rem 1.6rem 1.6rem;
    }
  }
}

/* Navbar User Info */
.lp-user-container-info {
  @include media-breakpoint-up(xl) {
    .collapsed & {
      display: none;
    }
  }
}

/* Welcome Page */
.lp-welcome-form {
  .form-label {
    @include media-breakpoint-only(md) {
      font-size: 1.4rem;
    }
  }
}

/* AuthLayout Page */
.lp-auth-page {
  @include media-breakpoint-down(xl) {
    padding: 1.6rem;
  }

  .lp-auth-page-left {
    @include media-breakpoint-only(xl) {
      background-size: auto 30rem;
    }

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  .lp-auth-page-right {
    .mobile-logo {
      @include media-breakpoint-up(xl) {
        display: none;
      }

      @media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
        position: absolute;
        top: 2.4rem;
      }

      @media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
        margin: 2.4rem auto;
      }

      img {
        @include media-breakpoint-down(sm) {
          height: 5rem;
        }
      }
    }

    .lp-auth-page-wrapper {
      @include media-breakpoint-down(xl) {
        padding: 2.4rem 0;
      }
    }
  }

  .lp-copyright {
    @include media-breakpoint-up(xl) {
      margin-bottom: 1.6rem;
    }
  }
}

/* Card */
.card.with-list {
  .card-header {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .card-body {
    @include media-breakpoint-down(md) {
      max-height: 100%;
    }
  }
}

/* Summary Box */
.lp-summary-boxes-area {
  @for $i from 1 through 16 {
    &.boxes-#{$i} {
      --boxes: #{$i};
    }
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(min(var(--boxes, 1), 4), 1fr);

    &.boxes-5,
    &.boxes-6,
    &.boxes-9 {
      grid-template-columns: repeat(min(var(--boxes, 1), 3), 1fr);
    }
  }

  @include media-breakpoint-between(lg,xxl) {
    grid-template-columns: repeat(min(var(--boxes, 1), 3), 1fr);
  }

  @include media-breakpoint-between(md,lg) {
    grid-template-columns: repeat(min(var(--boxes, 1), 2), 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(min(var(--boxes, 1), 1), 1fr);
  }
}

.lp-summary-box {
  .icon {
    @include media-breakpoint-up(xl) {
      font-size: 3rem;
      padding: 1rem;
    }

    @include media-breakpoint-down(xl) {
      font-size: 2.4rem;
      padding: 0.8rem;
    }
  }
}

/* Grid Buttons */
@media (max-height: 899px) {
  .lp-grid-btns {
    > .dropdown {
      position: static;

      .dropdown-menu {
        height: auto;
        max-height: calc(100% - 6.4rem);
        overflow-y: auto;
      }
    }
  }
}

/* Quick Search Panel */
.lp-quick-search-panel {
  @include media-breakpoint-up(xl) {
    display: flex;
  }

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

/* Content */
.lp-body-content-right {
  @include media-breakpoint-down(xl) {
    z-index: 9;
  }
}

/* Emails List */
.lp-slide-panel-content {
  .lp-box-list {
    .lp-box-list-item {
      .lp-label-value {
        @include media-breakpoint-down(sm) {
          display: block;
          width: 100%;
        }
      }
    }

    .lp-emails-filter {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
  
      .form-control,
      .lp-select {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
}

.lp-email-action-btns {
  .btn {
    @include media-breakpoint-down(sm) {
      font-size: 1.8rem;
      padding: 1rem;
    }

    svg {
      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    span {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

/* Client Ledger */
.react-tabs {
  .card.with-grid {
    .card-body {
      .lp-client-ledger-select {
        @include media-breakpoint-down(lg) {
          position: static;
          padding: 0.8rem 0.8rem 0;
        }
  
        .lp-select {
          @include media-breakpoint-down(lg) {
            min-width: 20rem;
          }
        }
      }
    }
  }
}

/* Empty Grid */
.grid {
  .ag-overlay {
    .lp-grid-empty {
      .image {
        @include media-breakpoint-down(md) {
          height: 15rem;
          width: 23rem;
        }
      }
    
      .text {
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

/* Grid List */
.lp-page-content {
  .lp-list-grid {
    li {
      @include media-breakpoint-down(sm) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .lp-list-grid-item-name {
      @include media-breakpoint-down(sm) {
        padding-left: 0.8rem;
      }
    }

    .lp-list-grid-item-type {
      @include media-breakpoint-down(sm) {
        padding-top: 0;
        width: 100%;
      }
    }

    .lp-list-grid-item-actions {
      @include media-breakpoint-down(sm) {
        justify-content: flex-start;
        padding-top: 0;
      }
    }
  }
}

/* Delegate & Delegated Ltes Header */
.lp-delegated-ltes-select {
  @include media-breakpoint-down(lg) {
    position: relative !important;
  }
}

/* React Tabs */
.react-tabs {
  @include media-breakpoint-between(md,xl) {
    height: calc(100% - 6.4rem);
  }

  .react-tabs__tab-list {
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  .react-tabs__tab-panel {
    @include media-breakpoint-up(md) {
      height: calc(100% - 1.4rem);
      overflow-y: scroll;
    }

    &.react-tabs__tab-panel--selected {
      .row:has(.with-grid) {
        @include media-breakpoint-up(xl) {
          height: 100%;
          max-height: 100%;
          min-height: 55rem;
          transition: max-height 0.2s ease-in-out;
        }
        
        > .col {
          @include media-breakpoint-up(xl) {
            height: 100%;
          }
        }
      }
    }
  }
}

.lp-tabs-mobile {
  @include media-breakpoint-between(md,xl) {
    border-bottom: 0.1rem solid var(--lp-secondary-400);
    margin: 0 -2.4rem;
    padding: 0 2.4rem 0.8rem;
  }
}

/* Calendar */
.lp-calendar-toolbar {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.lp-calendar-date {
  @include media-breakpoint-down(md) {
    margin: 0.8rem auto;
  }
}

.rbc-header {
  span {
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }
}

.rbc-event {
  @include media-breakpoint-down(sm) {
    min-height: 2rem;
  }
}

.rbc-week-view {
  .rbc-header {
    .rbc-button-link {
      strong {
        @include media-breakpoint-down(md) {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.rbc-week-view,
.rbc-month-view {
  .rbc-event {
    .rbc-event-label,
    .rbc-event-content {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.card {
  .lp-calendar-date {
    &.appointments {
      @media screen and (max-width: 1299px) {
        margin: 0;
        padding-bottom: 0;
        position: relative;
      }

      @include media-breakpoint-down(sm) {
        align-items: flex-start;
        flex-wrap: wrap;
      }

      > span {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            @include media-breakpoint-down(sm) {
              max-width: 17.5rem;
            }
          }
        }
      }
    }

    &.free-busy {
      .form-label {
        @include media-breakpoint-down(sm) {
          font-size: 1.5rem;
        }
      }
    }
  }
}

/* Dashboard */
.lp-dashboard-page {
  @include media-breakpoint-down(xl) {
    padding-top: 7.6rem;
  }

  .accordion {
    .accordion-item {
      &.lp-summary-boxes-accordion {
        @include media-breakpoint-down(xl) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .dashboard .rbc-month-row {
    flex-basis: 15rem;
  }
}

/* Slide Panel Buttons */
.lp-slide-panel {
  .lp-slide-panel-sticky-bottom {
    .col.flex-wrap {
      .btn {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
}

/* Delegate Message */
.lp-body-content {
  &.delegate {
    &:has(.lp-navbar.collapsed),
    &:has(.lp-navbar.expanded) {
      .lp-delegate-message {
        @include media-breakpoint-down(xl) {
          left: 0;
        }
      }
    }
  }
}
