/* Title */
.lp-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  /* Page Title */
  &.page {
    .lp-title-text {
      font-size: 2.4rem;
      font-weight: 700;

      span {
        &:not(.lp-tooltip-icon) {
          color: var(--lp-primary);
        }

        &.lp-sync-message {
          align-items: center;
          border-radius: 1rem;
          color: var(--lp-text);
          font-size: 1.2rem;
          font-weight: 400;
          margin-left: 0.8rem;
          padding: 0.2rem 0.8rem 0.2rem 0.2rem;

          svg {
            font-size: 1.6rem;
            margin-right: 0.4rem;
          }

          &.syncing {
            background: rgba(var(--lp-warning-rgb), 0.5);
            color: var(--lp-text);
          }

          &.succeded {
            background: rgba(var(--lp-success-rgb), 0.35);
            color: var(--lp-text);
          }

          &.failed {
            background: rgba(var(--lp-danger-rgb), 0.15);
            color: var(--lp-danger);
          }
        }

        &.lp-shared-message {
          align-items: center;
          background: rgba(var(--lp-warning-rgb), 0.5);
          border-radius: 0.4rem;
          color: var(--lp-text);
          font-size: 1.2rem;
          font-weight: 400;
          margin-left: 0.8rem;
          padding: 0.2rem 0.8rem 0.2rem 0.4rem;
          vertical-align: middle;

          svg {
            color: var(--lp-text);
            fill: var(--lp-text);
            font-size: 1.6rem;
            margin-right: 0.4rem;
          }

          span:not(.lp-tooltip-icon) {
            color: var(--lp-text);
          }
        }
      }
    }

    .lp-title-actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0.8rem;

      .btn {
        font-size: 1.4rem;
      }
    }

    + .react-tabs {
      margin-top: -0.8rem;
    }
  }

  /* Section Title */
  &.section {
    border-bottom: 0.1rem solid var(--lp-secondary-400);
    margin-bottom: 0.8rem;
    padding-bottom: 0.4rem;

    .lp-title-text {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 1.6rem;
      font-weight: 600;
      gap: 0.8rem;
      line-height: 1.5;
      margin: 0;
    }

    .lp-title-actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      justify-content: space-between;

      .link {
        align-items: center;
        color: var(--lp-success);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        line-height: 1;

        svg {
          font-size: 2rem;
        }
      }

      .btn-icon,
      .dropdown-toggle {
        padding: 0.4rem;
      }

      .dropdown-toggle {
        svg {
          margin: 0;
        }
      }
    }

    + .lp-errors {
      max-width: 100%;
    }
  }

  &.boxes {
    cursor: pointer;

    + div {
      overflow: hidden;
      transition: max-height 0.4s ease-out;

      &.collapsed {
        max-height: 0;
      }

      &.expanded {
        max-height: 78rem;
      }
    }
  }

  + .card,
  + .card.with-grid,
  + .card.with-list {
    height: calc(100% - 3.7rem);
  }
}
