/* Grid Buttons */
.lp-grid-btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: flex-end;
  padding: 0.8rem;

  + .grid {
    height: calc(100% - 5.6rem);
  }
}

/* Grid Actions Buttons */
.lp-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.lp-actions-btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-end;
  width: 100%;

  .btn {
    + .btn {
      margin-left: 0.8rem;
    }

    &.btn-secondary-400 {
      .ag-row-selected & {
        background-color: var(--lp-white);
      }
    }
  }
}

.lp-actions-more-menu {
  background: var(--lp-white);
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  color: var(--lp-text);
  font-size: 1.4rem;
  line-height: 1.5;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 99;

  li {
    cursor: pointer;
    padding: 0.8rem 1.6rem;

    &:hover {
      background: rgba(var(--lp-secondary-rgb), 0.1);
    }

    &.delete {
      background-color: rgba(var(--lp-danger-rgb), 0.25);
      border-radius: 0 0 0.4rem 0.4rem;
      color: var(--lp-danger);
      transition: .2s ease-in-out;

      &:hover {
        background-color: rgba(var(--lp-danger-rgb), 0.35);
      }
    }
  }
}

.lp-advanced-filters-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: flex-start;
  margin-left: auto;

  > div {
    color: var(--lp-secondary);
  }

  .btn {
    font-size: 1.4rem;
    line-height: 1.4;
    padding: 0.4rem 0.8rem;
  }
}
